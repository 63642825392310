








import { Component, Vue } from "vue-property-decorator";
import FormularioFinalizado from "@/components/FormularioEscolar/FormularioFinalizadoComponent.vue";

@Component({
  components: {
    FormularioFinalizado
  }
})
export default class FormularioTerminadoView extends Vue {}
