









































import { Vue } from "vue-property-decorator";

export default class FormularioFinalizadoComponent extends Vue {
  private sendMail(correo: string) {
    window.open("mailto:" + correo);
  }
}
